<template>
  <section>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card class="mb-6 ml-6 mr-2 mt-4 ">
          <v-form ref="form" lazy-validation>
            <v-card-title
              >Modification Actualités : {{ actualityInfo.title }}</v-card-title
            >
            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="actualityInfo.title"
                    label="Titre"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="actualityInfo.overview"
                    label="Overview"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  v-if="
                    actualityInfo.actualityCategory !== 'PERSONNAGE' &&
                      actualityInfo.actualityCategory !== 'PATRIMOINE' &&
                      actualityInfo.actualityCategory !== 'ARTICLE'
                  "
                >
                  <v-text-field
                    label="Sous-Titre"
                    v-model="actualityInfo.subtitle"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-if="
                    actualityInfo.actualityCategory !== 'PERSONNAGE' &&
                      actualityInfo.actualityCategory !== 'PATRIMOINE'
                  "
                >
                  <VueEditor
                    v-model="actualityInfo.description"
                    auto-grow
                    outlined
                    label="Description"
                    placeholder="Description"
                  >
                  </VueEditor>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-row align="center">
                    <v-col cols="auto" style="margin-top: 16px;">
                      <span>Premium:</span>
                    </v-col>
                    <v-col cols="auto">
                      <v-switch
                        v-model="actualityInfo.isLocked"
                        hide-details
                        inset
                      ></v-switch>
                    </v-col>
                    <v-col cols="auto">
                      <v-icon color="#00a325" v-if="!actualityInfo.isLocked">
                        {{ icons.mdiLockOpen }}
                      </v-icon>
                      <v-icon color="warning" v-if="actualityInfo.isLocked">
                        {{ icons.mdiLock }}
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="4" v-if="actualityInfo.title">
                  <vue-upload-multiple-image
                    style="margin-left: 160px;"
                    v-model="images"
                    :data-images="images"
                    name="images"
                    drag-text="Veuillez ajoutez une images svp!"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :min-image="1"
                    :multiple="false"
                    :showEdit="false"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                  >
                  </vue-upload-multiple-image>
                  <v-progress-linear
                    v-if="showlowding && uploadPercentage < 100"
                    style="max-width: 190px;margin-left: 160px; "
                    color="primary"
                    indeterminate
                    rounded
                    height="15"
                    ><strong
                      >{{ Math.ceil(uploadPercentage) }}%</strong
                    ></v-progress-linear
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  v-if="
                    (actualityInfo.title &&
                      actualityInfo.actualityCategory === 'PERSONNAGE') ||
                      (actualityInfo.title &&
                        actualityInfo.actualityCategory === 'PATRIMOINE') ||
                      (actualityInfo.title &&
                        actualityInfo.actualityCategory === 'ARTICLE')
                  "
                >
                  <vue-upload-multiple-image
                    :idUpload="imagesIdBanner"
                    style="margin-left: 160px;"
                    v-model="imagesBanner"
                    :data-images="imagesBanner"
                    name="images"
                    drag-text="Veuillez ajoutez une images Banner!"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :min-image="1"
                    :multiple="false"
                    :showEdit="false"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageSuccessBanner"
                    @before-remove="beforeRemoveBanner"
                  >
                  </vue-upload-multiple-image>
                  <v-progress-linear
                    v-if="showlowdingBanner && uploadPercentageBanner < 100"
                    style="max-width: 190px;margin-left: 160px; "
                    color="primary"
                    indeterminate
                    rounded
                    height="15"
                    ><strong
                      >{{ Math.ceil(uploadPercentageBanner) }}%</strong
                    ></v-progress-linear
                  >
                </v-col>
                <v-col cols="12" sm="12" md="4"> </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <div class="d-flex flex-row-reverse">
                    <v-btn
                      v-if="
                        actualityInfo.actualityCategory === 'PERSONNAGE' ||
                          actualityInfo.actualityCategory === 'PATRIMOINE'
                      "
                      :disabled="
                        actualityInfo.title.length <= 0 ||
                          actualityInfo.imgId.length <= 0
                      "
                      class="info"
                      @click="dialogEditActuality = true"
                    >
                      Suivant
                    </v-btn>
                    <v-btn
                      v-else-if="actualityInfo.actualityCategory === 'ARTICLE'"
                      :disabled="
                        actualityInfo.title.length <= 0 ||
                          actualityInfo.description.length <= 0 ||
                          actualityInfo.imgId.length <= 0
                      "
                      class="info"
                      @click="dialogEditActuality = true"
                    >
                      Suivant
                    </v-btn>
                    <v-btn
                      v-else
                      :disabled="
                        actualityInfo.title.length <= 0 ||
                          actualityInfo.subtitle.length <= 0 ||
                          actualityInfo.description.length <= 0 ||
                          actualityInfo.imgId.length <= 0
                      "
                      class="info"
                      @click="dialogEditActuality = true"
                    >
                      Suivant
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
        <v-row
          v-if="
            actualityInfo.actualityCategory == 'PERSONNAGE' ||
              actualityInfo.actualityCategory == 'PATRIMOINE'
          "
        >
          <v-col cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <v-btn
                  @click="dialogAddCard = true"
                  class="info  ml-6 mr-2 mt-4 "
                >
                  <v-icon left> {{ icons.mdiDatabasePlusOutline }} </v-icon>
                  Ajouter un Élément
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card
          class="mb-6 ml-6 mt-6 mr-2"
          v-if="
            actualityInfo.actualityCategory == 'PERSONNAGE' ||
              actualityInfo.actualityCategory == 'PATRIMOINE'
          "
        >
          <v-card-title>Élément d'actualité</v-card-title>
          <v-data-table
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 30],
            }"
            class="elevation-1"
            item-key="id"
            :headers="headers"
            :items="ActualityDescItemist"
          >
            <template v-slot:item.modifier="{ item }">
              <v-icon @click="redirectToEditDescItem(item)">
                {{ icons.mdiPencil }}
              </v-icon>
            </template>
            <template v-slot:item.supprimer="{ item }">
              <v-icon @click="suppressionAction(item.id)">
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogEditActuality" max-width="600">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir Modifier l'actualité ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="dialogEditActuality = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="UpdateActuality()">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogAddCard" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Informations d'élément </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="title"
                      label="Nom"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <VueEditor
                      v-model="description"
                      auto-grow
                      outlined
                      label="Description"
                      placeholder="Description"
                    >
                    </VueEditor>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error darken-1 error"
              text
              @click="reinitialisationFormulaire()"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="description.length <= 0 || title.length <= 0"
              color="info darken-1 "
              @click="dialogConfirmAddDescItem = true"
              text
            >
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogConfirmAddDescItem" max-width="530">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir ajouter l'élément ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="dialogConfirmAddDescItem = false"
            >
              Annuler
            </v-btn>

            <v-btn
              color="info darken-1 "
              text
              @click="ajoutActualityDescItem()"
            >
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog max-width="530" v-model="dialogRemoveDescItem">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir supprimer l'actualité ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="deleteDescItem()" color="info darken-1 " text>
              Confirmer
            </v-btn>
            <v-btn
              @click="dialogRemoveDescItem = false"
              color="error darken-1 error"
              text
            >
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogEditDescItem" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Informations d'élément </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="actualityDescItemInfo.title"
                      label="Nom"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <VueEditor
                      v-model="actualityDescItemInfo.description"
                      auto-grow
                      outlined
                      label="Description"
                      placeholder="Description"
                    >
                    </VueEditor>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error darken-1 error"
              @click="dialogEditDescItem = false"
              text
            >
              Annuler
            </v-btn>
            <v-btn
              @click="dialogConfirmerEditDescItem = true"
              :disabled="
                actualityDescItemInfo.description.length <= 0 ||
                  actualityDescItemInfo.title.length <= 0
              "
              color="info darken-1 "
              text
            >
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogConfirmerEditDescItem" max-width="530">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir Modifier l'élément ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="dialogConfirmerEditDescItem = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="EditActualityDescItem()">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>
<script>
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { component as VueCodeHighlight } from "vue-code-highlight";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencil,
  mdiDatabasePlusOutline,
  mdiLockOpen,
  mdiLock,
} from "@mdi/js";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import axios from "axios";
import Constant from "@/utils/constants";
import {
  getAllActualityCategory,
  getAllActualityDescItem,
} from "@/views/qorani/services/services";
export default {
  components: { VueUploadMultipleImage, VueEditor, VueCodeHighlight },
  data() {
    return {
      imagesId: "1",
      imagesIdBanner: "0",
      showLoadingBanner: false,
      showlowding: false,
      showlowdingBanner: false,
      dialogConfirmerEditDescItem: false,
      dialogConfirmAddDescItem: false,
      description: "",
      title: "",
      overview: "",
      headers: [
        { text: "Titre", value: "title" },
        { text: "Description", value: "description" },
        { text: "Modifier", value: "modifier", align: "center" },
        { text: "Supprimer", value: "supprimer", align: "center" },
      ],
      dialogEditActuality: false,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPencil,
        mdiDatabasePlusOutline,
        mdiLockOpen,
        mdiLock,
      },
      ActualityDescItemist: [],
      ActualityCategoryList: [],
      images: [],
      imagesBanner: [],
      actualityInfo: {
        id: "",
        title: "",
        overview: "",
        subtitle: "",
        description: "",
        imgUrl: "",
        imgId: "",
        isLocked: "",
        imgBannerUrlId: "",
        imgBannerUrl: "",
        actualityCategory: [],
      },
      actuality: {
        id: "",
        title: "",
        subtitle: "",
        description: "",
        imgUrlId: "",
        imgBannerUrlId: "",
        isLocked: "",
        imgBannerUrlId: "",
      },
      actualityDescItem: {
        title: "",
        description: "",
        actualityId: "",
      },
      actualityDescItemInfo: {
        title: "",
        description: "",
        id: "",
        actualityId: "",
      },
      dialogAddCard: false,
      DescItemIdSupprimmer: "",
      dialogRemoveDescItem: false,
      dialogEditDescItem: false,
      uploadPercentage: null,
      uploadPercentageBanner: null,
      fakePercentage: null,
      imageInfo: {
        fileName: "",
        fileUrl: "",
      },
      imageInfoBanner: {
        fileName: "",
        fileUrl: "",
      },
    };
  },
  async beforeRouteEnter(to, from, next) {
    const actualityId = to.params.id;
    try {
      const response = await axios.get(
        `${Constant.QORANI}actuality/get/${actualityId}`
      );
      next((vm) => {
        vm.actualityInfo = response.data;
        vm.images.push({
          name: "",
          path: response.data.imgUrl,
          highlight: 1,
          default: 1,
        });
        if (response.data.imgBannerUrl) {
          vm.imagesBanner.push({
            name: "",
            path: response.data.imgBannerUrl,
            highlight: 1,
            default: 1,
          });
        }
      });
    } catch (error) {
      console.error("Error fetching product details:", error);
      next(null);
    }
  },
  methods: {
    async EditActualityDescItem() {
      const response = await fetch(
        Constant.QORANI +
          "actualityDescItem/edit/" +
          this.actualityDescItemInfo.id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.actualityDescItemInfo),
        }
      );

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de l'ajout du theme, veuillez réessayez plus tard, Merci."
        );
      }
    },
    redirectToEditDescItem(item) {
      this.actualityDescItemInfo.title = item.title;
      this.actualityDescItemInfo.description = item.description;
      this.actualityDescItemInfo.id = item.id;
      this.actualityDescItemInfo.actualityId = this.$route.params.id;
      this.dialogEditDescItem = true;
    },
    async deleteDescItem() {
      const response = await fetch(
        Constant.QORANI +
          `actualityDescItem/delete/${this.DescItemIdSupprimmer}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de la suppression du juz, veuillez réessayez plus tard, Merci."
        );
        this.dialogRemoveDescItem = false;
      }
    },
    suppressionAction(item) {
      this.DescItemIdSupprimmer = item;
      this.dialogRemoveDescItem = true;
    },
    async ajoutActualityDescItem() {
      this.actualityDescItem.title = this.title;
      this.actualityDescItem.description = this.description;
      this.actualityDescItem.actualityId = this.$route.params.id;

      const responseCategorie = await fetch(
        Constant.QORANI + "actualityDescItem/add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.actualityDescItem),
        }
      );

      try {
        this.reinitialisationFormulaire();
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de l'ajout du theme, veuillez réessayez plus tard, Merci."
        );
      }
    },
    reinitialisationFormulaire() {
      this.title = "";
      this.description = "";
      this.dialogAddCard = false;
    },
    async UpdateActuality() {
      this.actuality.title = this.actualityInfo.title;
      this.actuality.overview = this.actualityInfo.overview;
      this.actuality.subtitle = this.actualityInfo.subtitle;
      this.actuality.description = this.actualityInfo.description;
      this.actuality.isLocked = this.actualityInfo.isLocked;
      this.actuality.imgUrlId = this.actualityInfo.imgId;
      this.actuality.imgBannerUrlId = this.actualityInfo.imgBannerUrlId;
      const response = await fetch(
        Constant.QORANI + "actuality/edit/" + this.actualityInfo.id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.actuality),
        }
      );

      try {
        this.$router.push({
          name: "Actuality",
        });
      } catch (error) {
        alert(
          "Une erreur est survenue lors de l'ajout du actuality, veuillez réessayez plus tard, Merci."
        );
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      const imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf(".") + 1);
      const contentType = `image/${ext}`;
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}${(
        currentDateTime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getHours()
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}${currentDateTime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const newImageName = `${formattedDateTime}`;
      const file = this.convertImagePathToFile(fileList[index]);

      const storageRef = firebase
        .app()
        .storage()
        .ref(`actuality/${newImageName}`);
      try {
        this.showlowding = true;
        const metadata = {
          contentType: contentType,
        };
        const uploadTask = storageRef.put(file, metadata);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadPercentage = progress;
          },
          (error) => {
            alert(`Error uploading image ${error.message}`);
          },
          async () => {
            const url = await storageRef.getDownloadURL();
            this.imageUrl = url;
            this.imageInfo.fileName = imageName;
            this.imageInfo.fileUrl = url;

            try {
              const response = await fetch(Constant.QORANI + "files/upload", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.imageInfo),
              });

              if (response.ok) {
                const responseData = await response.json();
                this.actualityInfo.imgId = responseData.id;
                this.showLoading = false;
              } else {
                this.showLoading = false;
                console.error(
                  "Error uploading image. Server response status:",
                  response.status
                );
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }

            this.showLoading = false;
          }
        );
        this.showLoading = false;
      } catch (error) {
        this.showlowding = false;
        alert(`Error uploading image ${error.message}`);
      }
    },
    uploadImageSuccessBanner(formData, index, fileList) {
      const imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf(".") + 1);
      const contentType = `image/${ext}`;
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}_${(
        currentDateTime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getHours()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const newImageName = `${imageName}_${formattedDateTime}`;
      const file = this.convertImagePathToFile(fileList[index]);

      const storageRef = firebase
        .app()
        .storage()
        .ref(`actuality/${newImageName}`);
      try {
        this.showlowdingBanner = true;
        const metadata = {
          contentType: contentType,
        };
        const uploadTask = storageRef.put(file, metadata);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadPercentageBanner = progress;
          },
          (error) => {
            alert(`Error uploading image ${error.message}`);
          },
          async () => {
            const url = await storageRef.getDownloadURL();
            this.imageUrlBanner = url;
            this.imageInfoBanner.fileName = imageName;
            this.imageInfoBanner.fileUrl = url;

            try {
              const response = await fetch(Constant.QORANI + "files/upload", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.imageInfoBanner),
              });

              if (response.ok) {
                const responseData = await response.json();
                this.actualityInfo.imgBannerUrlId = responseData.id;
                this.showLoadingBanner = false;
              } else {
                this.showLoadingBanner = false;
                console.error(
                  "Error uploading image. Server response status:",
                  response.status
                );
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }

            this.showLoadingBanner = false;
          }
        );
        this.showLoadingBanner = false;
      } catch (error) {
        this.showlowdingBanner = false;
        alert(`Error uploading image ${error.message}`);
      }
    },
    beforeRemove(index, done, fileList) {
      this.showlowding = false;
      this.fakePercentage = 0;
      this.uploadPercentage = 0;
      const r = confirm("remove image");
      if (r === true) {
        const indexInArrayImages = this.images.indexOf(fileList[index].path);
        this.actualityInfo.imgUrl = "";
        this.actualityInfo.imgId = "";
        if (index > -1) {
          this.images.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    beforeRemoveBanner(index, done, fileList) {
      this.showlowdingBanner = false;
      this.fakePercentage = 0;
      this.uploadPercentageBanner = 0;
      const r = confirm("remove image");
      if (r === true) {
        const indexInArrayImages = this.imagesBanner.indexOf(
          fileList[index].path
        );
        this.actualityInfo.imgBannerUrlId = null;
        this.actualityInfo.imgBannerUrl = null;
        if (index > -1) {
          this.imagesBanner.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    convertImagePathToFile(element) {
      const byteString = atob(element.path.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ia], { type: "image/jpeg" });

      return new File([blob], element.name);
    },
  },
  async created() {
    this.ActualityDescItemist = await getAllActualityDescItem(
      this.$route.params.id
    );
    this.ActualityCategoryList = await getAllActualityCategory();
    this.ActualityCategoryList.forEach((item) => {
      item.champName = item.category.toLowerCase().replace(/_/g, " ");
    });
  },
};
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
</style>
